import { Prisma, Shop } from "@prisma/client";
import { useStorage } from "@vueuse/core";
import { toast } from "vue3-toastify";

export const useAppStore = defineStore("appStore", () => {
  const notificationStore = useNotificationStore();
  const isLoading = ref(false);
  const showDrawer = ref(false);
  const openTabs: any = ref({});
  const selectedShop = useStorage("selectedShop", {} as Shop);
  const shops = ref<Shop[]>([]);

  const onOpenTab = (key: string) => {
    openTabs.value = {};
    openTabs.value[key] = true;
    showDrawer.value = false;
  };

  const fetchShops = async () => {
    shops.value = await $fetch("/api/adsmanager-ewo/shop", {
      method: "post",
    });
  };

  const initStore = async () => {
    await fetchShops();

    await notificationStore.init();

    const foo = useSupabaseClient()
      .channel("shop-update-channel")
      .on(
        "postgres_changes",
        {
          event: "UPDATE",
          schema: "public",
          table: "shop",
          filter: `id=eq.${selectedShop.value.id}`,
        },
        (payload) => {
          selectedShop.value = payload.new as Shop;
        }
      )
      .subscribe();

    const index = shops.value.findIndex((shop) => shop.id === selectedShop.value.id);

    if (index > -1) {
      selectedShop.value = shops.value[index];
      return;
    }

    selectedShop.value = shops.value[0];
  };

  const updateShop = async (shop: Partial<Shop>) => {
    await $fetch(`/api/adsmanager-ewo/shop/${selectedShop.value.id}`, {
      method: "PATCH",
      body: {
        ...shop,
      } as Prisma.ShopUpdateInput,
    });
  };
  const menus = computed(() => {
    return [
      {
        label: "Budget Manager",
        icon: "pi pi-fw pi-chart-bar",
        key: "Budgets",
        items: [
          {
            label: "Dashboard",
            icon: "pi pi-fw pi-chart-bar",
            key: "Budgets",
            to: `/budget-manager`,

            command: () => {
              onOpenTab("Budgets");
            },
          },
          {
            label: "Einstellungen",
            icon: "pi pi-cog",
            key: "Budgets",
            to: `/budget-manager/settings`,

            command: () => {
              onOpenTab("Budgets");
            },
          },
        ],
      },
      {
        label: "Keyword Seeding",
        icon: "pi pi-fw pi-check-square",
        key: "KeywordSeeding",
        items: [
          {
            key: "2_0",
            label: "Report",
            command: () => {
              onOpenTab("KeywordSeeding");
            },
            to: `/keyword-seeding/report-list`,
            icon: "pi pi-id-card",
          },
          {
            key: "2_0",
            label: "Settings",
            command: () => {
              onOpenTab("KeywordSeedingSettings");
            },
            to: `/keyword-seeding/settings`,
            icon: "pi pi-id-card",
          },
        ],
      },
      {
        label: "Traffic Report",
        icon: "pi pi-file",
        key: "TrafficReport",
        items: [
          {
            key: "2_0",
            label: "Traffic Report Links",
            command: () => {
              onOpenTab("TrafficReport");
            },
            to: `/traffic-report/links`,
            icon: "pi pi-id-card",
          },
          {
            key: "2_1",
            label: "Traffic Report Warengruppe",
            command: () => {
              onOpenTab("TrafficReport");
            },
            to: `/traffic-report/product-groups`,
            icon: "pi pi-user",
          },
        ],
      },
    ];
  });

  return {
    showDrawer,
    menus,
    openTabs,
    selectedShop,
    shops,
    fetchShops,
    initStore,
    updateShop,
    isLoading,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAppStore, import.meta.hot));
}
