export default defineNuxtRouteMiddleware((to, _from) => {
  const user = useSupabaseUser();

  if (to.path === "/login" || to.path === "/signup") {
    if (!user.value) {
      return;
    }
    navigateTo(_from.fullPath);
  }

  if (!user.value) {
    return navigateTo("/login");
  }
});
