import { Prisma } from "@prisma/client";

type NotificationWithProductGroup = Prisma.NotificationsGetPayload<{
  include: {
    product_group: true;
  };
}>;

export const useNotificationStore = defineStore("notificationStore", () => {
  const notifications = ref<NotificationWithProductGroup[]>([]);

  const dissmissNotifications = async () => {
    await $fetch(`/api/adsmanager-ewo/notifications`, {
      method: "DELETE",
      body: {
        where: {
          shop_id: appStore.selectedShop.id,
        },
      } as Prisma.NotificationsDeleteManyArgs,
    });
    notifications.value = [];
  };

  const init = async () => {
    await fetchNotifications();
    const foo = useSupabaseClient()
      .channel("custom-insert-channel")
      .on(
        "postgres_changes",
        {
          event: "INSERT",
          schema: "public",
          table: "notifications",
          filter: `shop_id=eq.${appStore.selectedShop.id}`,
        },
        async (payload) => {
          await fetchNotifications();
        }
      )
      .subscribe();
  };

  const appStore = useAppStore();
  const fetchNotifications = async () => {
    notifications.value = await $fetch(`/api/adsmanager-ewo/notifications`, {
      method: "POST",
      body: {
        orderBy: {
          created_at: "desc",
        },
        include: {
          product_group: true,
        },
        where: {
          dismissed: false,
          shop: {
            name: appStore.selectedShop.name,
          },
        },
      } as Prisma.NotificationsFindManyArgs,
    });
  };

  return {
    init,
    notifications,
    fetchNotifications,
    dissmissNotifications,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNotificationStore, import.meta.hot));
}
