import revive_payload_client_UkngwmHGnC from "/vercel/path0/node_modules/.pnpm/nuxt@3.6.0_@parcel+watcher@2.4.1_@types+node@20.14.6_@upstash+redis@1.31.5_@vercel+kv@0.2.2_e_lqwcikvcsgst6mpypiyywnvhue/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_uKeO95k5zs from "/vercel/path0/node_modules/.pnpm/nuxt@3.6.0_@parcel+watcher@2.4.1_@types+node@20.14.6_@upstash+redis@1.31.5_@vercel+kv@0.2.2_e_lqwcikvcsgst6mpypiyywnvhue/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_wsm15YgJXg from "/vercel/path0/node_modules/.pnpm/@nuxtjs+supabase@1.2.2_bufferutil@4.0.8_rollup@3.25.2_utf-8-validate@6.0.4/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.mjs";
import plugin_vue3_n7rtqWKJVA from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.4.11_rollup@3.25.2_typescript@4.9.5_vue@3.3.4/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/adsmanager-ewo/.nuxt/components.plugin.mjs";
import unhead_l2Khbtxye3 from "/vercel/path0/node_modules/.pnpm/nuxt@3.6.0_@parcel+watcher@2.4.1_@types+node@20.14.6_@upstash+redis@1.31.5_@vercel+kv@0.2.2_e_lqwcikvcsgst6mpypiyywnvhue/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_FXiSCENfBs from "/vercel/path0/node_modules/.pnpm/nuxt@3.6.0_@parcel+watcher@2.4.1_@types+node@20.14.6_@upstash+redis@1.31.5_@vercel+kv@0.2.2_e_lqwcikvcsgst6mpypiyywnvhue/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_IgfRjn2fkS from "/vercel/path0/node_modules/.pnpm/nuxt@3.6.0_@parcel+watcher@2.4.1_@types+node@20.14.6_@upstash+redis@1.31.5_@vercel+kv@0.2.2_e_lqwcikvcsgst6mpypiyywnvhue/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import prime_vue_LUh3hUhZMB from "/vercel/path0/apps/adsmanager-ewo/plugins/prime-vue.ts";
import sentry_client_shVUlIjFLk from "/vercel/path0/apps/adsmanager-ewo/plugins/sentry.client.ts";
import toastify_ScRgZgP9op from "/vercel/path0/apps/adsmanager-ewo/plugins/toastify.ts";
export default [
  revive_payload_client_UkngwmHGnC,
  router_uKeO95k5zs,
  supabase_client_wsm15YgJXg,
  plugin_vue3_n7rtqWKJVA,
  components_plugin_KR1HBZs4kY,
  unhead_l2Khbtxye3,
  prefetch_client_FXiSCENfBs,
  chunk_reload_client_IgfRjn2fkS,
  prime_vue_LUh3hUhZMB,
  sentry_client_shVUlIjFLk,
  toastify_ScRgZgP9op
]