import { default as _91_46_46_46id_93hzM2Zm8q3bMeta } from "/vercel/path0/apps/adsmanager-ewo/pages/budget-manager/[...id].vue?macro=true";
import { default as settingsULNgI15QKgMeta } from "/vercel/path0/apps/adsmanager-ewo/pages/budget-manager/settings.vue?macro=true";
import { default as indexANuM3C943gMeta } from "/vercel/path0/apps/adsmanager-ewo/pages/index.vue?macro=true";
import { default as report_45listONCUs7eZcPMeta } from "/vercel/path0/apps/adsmanager-ewo/pages/keyword-seeding/report-list.vue?macro=true";
import { default as _91_46_46_46product_45group_93HUzFH9QYDzMeta } from "/vercel/path0/apps/adsmanager-ewo/pages/keyword-seeding/reports/[id]/[...product-group].vue?macro=true";
import { default as _91id_93EBNle8htoBMeta } from "/vercel/path0/apps/adsmanager-ewo/pages/keyword-seeding/reports/[id].vue?macro=true";
import { default as campaigns4OFQgA0uxpMeta } from "/vercel/path0/apps/adsmanager-ewo/pages/keyword-seeding/settings/index/[id]/campaigns.vue?macro=true";
import { default as filterkeywordsh8assDu1AKMeta } from "/vercel/path0/apps/adsmanager-ewo/pages/keyword-seeding/settings/index/[id]/filterkeywords.vue?macro=true";
import { default as negative_45keywordlistsDy79SkNUbcMeta } from "/vercel/path0/apps/adsmanager-ewo/pages/keyword-seeding/settings/index/[id]/negative-keywordlists.vue?macro=true";
import { default as negative_45keywords0OX3bRIKucMeta } from "/vercel/path0/apps/adsmanager-ewo/pages/keyword-seeding/settings/index/[id]/negative-keywords.vue?macro=true";
import { default as _91id_93NfP0FOTmxnMeta } from "/vercel/path0/apps/adsmanager-ewo/pages/keyword-seeding/settings/index/[id].vue?macro=true";
import { default as indexzsM6cohEmuMeta } from "/vercel/path0/apps/adsmanager-ewo/pages/keyword-seeding/settings/index.vue?macro=true";
import { default as loginEPjpovLfbQMeta } from "/vercel/path0/apps/adsmanager-ewo/pages/login.vue?macro=true";
import { default as linksG61gWWlvriMeta } from "/vercel/path0/apps/adsmanager-ewo/pages/traffic-report/links.vue?macro=true";
import { default as product_45groupsA1Bvj2hT2BMeta } from "/vercel/path0/apps/adsmanager-ewo/pages/traffic-report/product-groups.vue?macro=true";
export default [
  {
    name: _91_46_46_46id_93hzM2Zm8q3bMeta?.name ?? "budget-manager-id",
    path: _91_46_46_46id_93hzM2Zm8q3bMeta?.path ?? "/budget-manager/:id(.*)*",
    meta: _91_46_46_46id_93hzM2Zm8q3bMeta || {},
    alias: _91_46_46_46id_93hzM2Zm8q3bMeta?.alias || [],
    redirect: _91_46_46_46id_93hzM2Zm8q3bMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/adsmanager-ewo/pages/budget-manager/[...id].vue").then(m => m.default || m)
  },
  {
    name: settingsULNgI15QKgMeta?.name ?? "budget-manager-settings",
    path: settingsULNgI15QKgMeta?.path ?? "/budget-manager/settings",
    meta: settingsULNgI15QKgMeta || {},
    alias: settingsULNgI15QKgMeta?.alias || [],
    redirect: settingsULNgI15QKgMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/adsmanager-ewo/pages/budget-manager/settings.vue").then(m => m.default || m)
  },
  {
    name: indexANuM3C943gMeta?.name ?? "index",
    path: indexANuM3C943gMeta?.path ?? "/",
    meta: indexANuM3C943gMeta || {},
    alias: indexANuM3C943gMeta?.alias || [],
    redirect: indexANuM3C943gMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/adsmanager-ewo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: report_45listONCUs7eZcPMeta?.name ?? "keyword-seeding-report-list",
    path: report_45listONCUs7eZcPMeta?.path ?? "/keyword-seeding/report-list",
    meta: report_45listONCUs7eZcPMeta || {},
    alias: report_45listONCUs7eZcPMeta?.alias || [],
    redirect: report_45listONCUs7eZcPMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/adsmanager-ewo/pages/keyword-seeding/report-list.vue").then(m => m.default || m)
  },
  {
    name: _91id_93EBNle8htoBMeta?.name ?? "keyword-seeding-reports-id",
    path: _91id_93EBNle8htoBMeta?.path ?? "/keyword-seeding/reports/:id()",
    children: [
  {
    name: _91_46_46_46product_45group_93HUzFH9QYDzMeta?.name ?? "keyword-seeding-reports-id-productgroup",
    path: _91_46_46_46product_45group_93HUzFH9QYDzMeta?.path ?? ":productgroup(.*)*",
    meta: _91_46_46_46product_45group_93HUzFH9QYDzMeta || {},
    alias: _91_46_46_46product_45group_93HUzFH9QYDzMeta?.alias || [],
    redirect: _91_46_46_46product_45group_93HUzFH9QYDzMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/adsmanager-ewo/pages/keyword-seeding/reports/[id]/[...product-group].vue").then(m => m.default || m)
  }
],
    meta: _91id_93EBNle8htoBMeta || {},
    alias: _91id_93EBNle8htoBMeta?.alias || [],
    redirect: _91id_93EBNle8htoBMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/adsmanager-ewo/pages/keyword-seeding/reports/[id].vue").then(m => m.default || m)
  },
  {
    name: indexzsM6cohEmuMeta?.name ?? "keyword-seeding-settings",
    path: indexzsM6cohEmuMeta?.path ?? "/keyword-seeding/settings",
    children: [
  {
    name: _91id_93NfP0FOTmxnMeta?.name ?? "keyword-seeding-settings-index-id",
    path: _91id_93NfP0FOTmxnMeta?.path ?? ":id()",
    children: [
  {
    name: campaigns4OFQgA0uxpMeta?.name ?? "keyword-seeding-settings-index-id-campaigns",
    path: campaigns4OFQgA0uxpMeta?.path ?? "campaigns",
    meta: campaigns4OFQgA0uxpMeta || {},
    alias: campaigns4OFQgA0uxpMeta?.alias || [],
    redirect: campaigns4OFQgA0uxpMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/adsmanager-ewo/pages/keyword-seeding/settings/index/[id]/campaigns.vue").then(m => m.default || m)
  },
  {
    name: filterkeywordsh8assDu1AKMeta?.name ?? "keyword-seeding-settings-index-id-filterkeywords",
    path: filterkeywordsh8assDu1AKMeta?.path ?? "filterkeywords",
    meta: filterkeywordsh8assDu1AKMeta || {},
    alias: filterkeywordsh8assDu1AKMeta?.alias || [],
    redirect: filterkeywordsh8assDu1AKMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/adsmanager-ewo/pages/keyword-seeding/settings/index/[id]/filterkeywords.vue").then(m => m.default || m)
  },
  {
    name: negative_45keywordlistsDy79SkNUbcMeta?.name ?? "keyword-seeding-settings-index-id-negative-keywordlists",
    path: negative_45keywordlistsDy79SkNUbcMeta?.path ?? "negative-keywordlists",
    meta: negative_45keywordlistsDy79SkNUbcMeta || {},
    alias: negative_45keywordlistsDy79SkNUbcMeta?.alias || [],
    redirect: negative_45keywordlistsDy79SkNUbcMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/adsmanager-ewo/pages/keyword-seeding/settings/index/[id]/negative-keywordlists.vue").then(m => m.default || m)
  },
  {
    name: negative_45keywords0OX3bRIKucMeta?.name ?? "keyword-seeding-settings-index-id-negative-keywords",
    path: negative_45keywords0OX3bRIKucMeta?.path ?? "negative-keywords",
    meta: negative_45keywords0OX3bRIKucMeta || {},
    alias: negative_45keywords0OX3bRIKucMeta?.alias || [],
    redirect: negative_45keywords0OX3bRIKucMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/adsmanager-ewo/pages/keyword-seeding/settings/index/[id]/negative-keywords.vue").then(m => m.default || m)
  }
],
    meta: _91id_93NfP0FOTmxnMeta || {},
    alias: _91id_93NfP0FOTmxnMeta?.alias || [],
    redirect: _91id_93NfP0FOTmxnMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/adsmanager-ewo/pages/keyword-seeding/settings/index/[id].vue").then(m => m.default || m)
  }
],
    meta: indexzsM6cohEmuMeta || {},
    alias: indexzsM6cohEmuMeta?.alias || [],
    redirect: indexzsM6cohEmuMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/adsmanager-ewo/pages/keyword-seeding/settings/index.vue").then(m => m.default || m)
  },
  {
    name: loginEPjpovLfbQMeta?.name ?? "login",
    path: loginEPjpovLfbQMeta?.path ?? "/login",
    meta: loginEPjpovLfbQMeta || {},
    alias: loginEPjpovLfbQMeta?.alias || [],
    redirect: loginEPjpovLfbQMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/adsmanager-ewo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: linksG61gWWlvriMeta?.name ?? "traffic-report-links",
    path: linksG61gWWlvriMeta?.path ?? "/traffic-report/links",
    meta: linksG61gWWlvriMeta || {},
    alias: linksG61gWWlvriMeta?.alias || [],
    redirect: linksG61gWWlvriMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/adsmanager-ewo/pages/traffic-report/links.vue").then(m => m.default || m)
  },
  {
    name: product_45groupsA1Bvj2hT2BMeta?.name ?? "traffic-report-product-groups",
    path: product_45groupsA1Bvj2hT2BMeta?.path ?? "/traffic-report/product-groups",
    meta: product_45groupsA1Bvj2hT2BMeta || {},
    alias: product_45groupsA1Bvj2hT2BMeta?.alias || [],
    redirect: product_45groupsA1Bvj2hT2BMeta?.redirect || undefined,
    component: () => import("/vercel/path0/apps/adsmanager-ewo/pages/traffic-report/product-groups.vue").then(m => m.default || m)
  }
]