import { defineNuxtPlugin, useRuntimeConfig } from "#app";
import * as Sentry from "@sentry/vue";
import type { Router } from "vue-router";

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();

  Sentry.init({
    enabled: !import.meta.env.DEV,
    app: nuxtApp.vueApp,
    autoSessionTracking: true,
    debug: config.public.ENV !== "production",
    dsn: config.public.SENTRY_DSN,
    release: config.public.SENTRY_RELEASE,
    environment: config.public.SENTRY_ENVIRONMENT,

    beforeSend(event) {
      const user = useSupabaseUser();
      Sentry.setUser({
        email: user.value?.email,
        id: user.value?.id,
        username: user.value?.user_metadata.name,
      });
      return event
    },
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(
          nuxtApp.$router as Router
        ),
        tracePropagationTargets: [config.public.SENTRY_TRACE_PROPAGATION_TARGET],
      }),
      new Sentry.Replay({
        mutationBreadcrumbLimit: 100,
        mutationLimit: 1000
      }),
    ],
    trackComponents: true,
    hooks: ["activate", "create", "destroy", "mount", "update"],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1,
  });



  return {
    provide: {
      sentrySetContext: Sentry.setContext,
      sentrySetUser: Sentry.setUser,
      sentrySetTag: Sentry.setTag,
      sentryAddBreadcrumb: Sentry.addBreadcrumb,
      sentryCaptureException: Sentry.captureException,
    },
  };
});
