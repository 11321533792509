import { defineNuxtPlugin } from "#app";
import Avatar from "primevue/avatar";
import BadgeDirective from "primevue/badgedirective";
import Button from "primevue/button";
import Calendar from "primevue/calendar";
import Card from "primevue/card";
import Checkbox from "primevue/checkbox";
import Chip from "primevue/chip";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup"; //optional for column grouping
import PrimeVue from "primevue/config";
import ConfirmationService from "primevue/confirmationservice";
import ConfirmDialog from "primevue/confirmdialog";
import ContextMenu from "primevue/contextmenu";
import DataTable from "primevue/datatable";
import Dialog from "primevue/dialog";
import DialogService from "primevue/dialogservice";
import Divider from "primevue/divider";
import Dropdown from "primevue/dropdown";
import DynamicDialog from "primevue/dynamicdialog";
import InputNumber from "primevue/inputnumber";
import InputSwitch from "primevue/inputswitch";
import InputText from "primevue/inputtext";
import Listbox from "primevue/listbox";
import Menu from "primevue/menu";
import MultiSelect from "primevue/multiselect";
import OverlayPanel from "primevue/overlaypanel";
import PanelMenu from "primevue/panelmenu";
import Password from "primevue/password";
import ProgressBar from "primevue/progressbar";
import Row from "primevue/row";
import ScrollTop from "primevue/scrolltop";
import Sidebar from "primevue/sidebar";
import Skeleton from "primevue/skeleton";
import SplitButton from "primevue/splitbutton";
import TabMenu from "primevue/tabmenu";
import TabPanel from "primevue/tabpanel";
import TabView from "primevue/tabview";
import Tag from "primevue/tag";
import Textarea from "primevue/textarea";
import Toast from "primevue/toast";

import ToastService from "primevue/toastservice";
import ToggleButton from "primevue/togglebutton";
import Tooltip from "primevue/tooltip";
import VirtualScroller from "primevue/virtualscroller";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(DialogService);
  nuxtApp.vueApp.use(ConfirmationService);
  nuxtApp.vueApp.use(ToastService);
  nuxtApp.vueApp.use(PrimeVue, { ripple: true });

  nuxtApp.vueApp.component("SplitButton", SplitButton);
  nuxtApp.vueApp.component("Tag", Tag);
  nuxtApp.vueApp.component("Dialog", Dialog);

  nuxtApp.vueApp.component("Checkbox", Checkbox);
  nuxtApp.vueApp.component("ProgressBar", ProgressBar);

  nuxtApp.vueApp.component("InputSwitch", InputSwitch);

  nuxtApp.vueApp.component("Listbox", Listbox);
  nuxtApp.vueApp.component("Textarea", Textarea);
  nuxtApp.vueApp.component("ScrollTop", ScrollTop);
  nuxtApp.vueApp.component("TabMenu", TabMenu);

  nuxtApp.vueApp.component("Calendar", Calendar);
  nuxtApp.vueApp.component("Divider", Divider);
  nuxtApp.vueApp.component("Button", Button);
  nuxtApp.vueApp.component("PanelMenu", PanelMenu);
  nuxtApp.vueApp.component("Avatar", Avatar);
  nuxtApp.vueApp.component("ToggleButton", ToggleButton);
  nuxtApp.vueApp.component("TabView", TabView);
  nuxtApp.vueApp.component("TabPanel", TabPanel);
  nuxtApp.vueApp.component("Toast", Toast);
  nuxtApp.vueApp.component("Password", Password);
  nuxtApp.vueApp.directive("tooltip", Tooltip);
  nuxtApp.vueApp.directive("badge", BadgeDirective);
  nuxtApp.vueApp.component("ContextMenu ", ContextMenu);
  nuxtApp.vueApp.component("InputNumber", InputNumber);
  nuxtApp.vueApp.component("DataTable", DataTable);
  nuxtApp.vueApp.component("Column", Column);
  nuxtApp.vueApp.component("ColumnGroup", ColumnGroup);
  nuxtApp.vueApp.component("Row", Row);
  nuxtApp.vueApp.component("VirtualScroller", VirtualScroller);
  nuxtApp.vueApp.component("MultiSelect", MultiSelect);
  nuxtApp.vueApp.component("Dropdown", Dropdown);
  nuxtApp.vueApp.component("Card", Card);
  nuxtApp.vueApp.component("Skeleton", Skeleton);
  nuxtApp.vueApp.component("DynamicDialog", DynamicDialog);
  nuxtApp.vueApp.component("InputText", InputText);
  nuxtApp.vueApp.component("Chip", Chip);
  nuxtApp.vueApp.component("ConfirmDialog", ConfirmDialog);
  nuxtApp.vueApp.component("OverlayPanel ", OverlayPanel);
  nuxtApp.vueApp.component("SideBar", Sidebar);
  nuxtApp.vueApp.component("Menu", Menu);
});
