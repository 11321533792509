<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
const appStore = useAppStore();
await appStore.initStore();
</script>

<style lang="scss">
.p-datatable-scrollable .p-datatable-thead,
.p-datatable-scrollable .p-datatable-tfoot {
  z-index: 0;
}
</style>
